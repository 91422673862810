.App {
  /* text-align: center; */
  padding: 1em;
  /*max-width: 240px;*/
  /* margin: 0 auto; */
  margin: 0 20%;
  display:grid;
  /* max-width:40%;			 */
}

h1 {
  color: #ff3e00;
  font-size: 4em;
  font-weight: 100;
}

input, select {
  /* display: block; */
  margin: 0 0 10px 0;
  border:2px solid;
  border-radius: 15px;
  text-align: center;
}

label {
  text-align: center;
}